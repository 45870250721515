/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 04-11-2024
* @author      :asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui for reporting nwe question to jira
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
* 
*/
import React, { useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormHelperText,
    Box,
    Button,
    Grid,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    DialogProps,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { validQuestionTrade } from '../../services/TradeConfigurationService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getVersionDetails } from '../../services/TenantService';
import { addJiraContent } from '../../services/UserService';
import CustomDialog from '../../pages/Dialog';

interface QuestionFormProps {
    open?: (value: boolean) => void;
    onClose?: (value: boolean) => void;
}

// Define the AddQuestion component and accept props of type QuestionFormProps
const AddQuestion: React.FC<QuestionFormProps> = ({ open, onClose }) => {
        const [openDialog, setOpenDialog] = useState<boolean>(false);
        const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
        const [createSuccess, setCreateSuccess] = useState<boolean>(false);
        const [error, setError] = useState<boolean>(false);
        const [versionDetails, setVersionDetails] = useState<any>();
        
        const [selectedIssue, setSelectedIssue] = useState<string | null>(null);
        const [question, setQuestion] = useState<string>('');
        const [expectedAnswer, setExpectedAnswer] = useState<string>('');
        const [optionInputs, setOptionInputs] = useState<string[]>(['']);
        const [tradeNames, setTradeNames] = useState<any[]>([]);
        const [selectedTradeItem, setSelectedTradeItem] = useState<any>('');
        
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
        const { accessToken } = useOidcAccessToken();
        
        const [jiraContent, setJiraContent] = useState<any>({
            type: 'Add Question',
            questions: '',
            answers: [],
            tradeName: ''
        });
        
        const [questionError, setQuestionError] = useState<boolean>(false);
        const [tradeError, setTradeError] = useState<boolean>(false);
    
        // Handle change in the current option input field
        const handleInputChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const newInputs = [...optionInputs];
            newInputs[index] = event.target.value;
            setOptionInputs(newInputs);
        };
    
        // Handle adding a new option
        const handleAddOption = () => {
            setOptionInputs([...optionInputs, '']);
        };
    
        // Fetch version details
        const getVersionDetail = async () => {
            try {
                const response = await getVersionDetails(accessToken);
                setVersionDetails(response.data);
            } catch (error) {
                console.error("Error fetching version details:", error);
            }
        };
    
        // Fetch trade names
        const handleTradeNames = async () => {
            try {
                const res = await validQuestionTrade(accessToken);
                if (res.data && res.ok) {
                    setTradeNames(res.data || []);
                }
            } catch (error) {
                console.error("Error fetching trade names:", error);
            }
        };
    
        // Issue handlers
        const issueHandlers = {
            Bug: () => setSelectedIssue('Bug'),
            Issue: () => setSelectedIssue('Issue'),
            'Missing Functionality': () => setSelectedIssue('Missing Functionality'),
            'Add Question': () => setSelectedIssue('Add Question'),
            Feedback: () => setSelectedIssue('Feedback')
        };
    
        useEffect(() => {
            getVersionDetail();
            handleTradeNames();
        }, []);
    
        const handleClose = () => {
            setOpenDialog(false);
        };
       
        const handleCloseDialog = () => {
            setOpenConfirmDialog(false);
            if(onClose){
            onClose(false);

            }
        };
    
        const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setQuestion(event.target.value);
        };
    
        const handleFormSubmit = async () => {
            let isValid = true;
    
            if (selectedIssue === 'Add Question' && !selectedTradeItem) {
                setTradeError(true);
                isValid = false;
            } else {
                setTradeError(false);
            }
    
            if (!question.trim()) {
                setQuestionError(true);
                isValid = false;
            } else {
                setQuestionError(false);
            }
    
            if (!isValid) return;
    //console.log(optionInputs,selectedTradeItem)
            const jiraContent = {
                type: "Add Question",
                questions: question,
                answers:optionInputs.length > 0 ? optionInputs : [],
                tradeName:selectedTradeItem,
                aseVersion: versionDetails?.aseVersion,
                gmsCloud: '0.4.0',
                tenantApiVersion: versionDetails?.tenantApiVersion,
                tenantBuildDate: versionDetails?.tenantApiBuildDate,
                aseBuildDate: versionDetails?.aseBuildDate
            };
    //console.log("jiracontent",jiraContent)
            try {
                const res = await addJiraContent(jiraContent, accessToken);
                if (res.ok && (res.status === 200 || res.status === 201)) {
                    setOpenConfirmDialog(true);
                    setCreateSuccess(true);
                    setError(false);
                }
            } catch (error) {
                console.error("Error adding Jira content:", error);
                setOpenConfirmDialog(true);
                setCreateSuccess(false);
                setError(true);
            }
    
            // Reset form fields
            setQuestion('');
            setExpectedAnswer('');
            setSelectedIssue(null);
            setOptionInputs(['']);
        };
    
        const GoBack = () => {
            setQuestion('');
            setExpectedAnswer('');
            setSelectedIssue(null);
            setOptionInputs(['']);
            if (onClose) {
                onClose(false); // Invoke onClose if it's provided, with a value indicating the desired state (e.g., close the dialog)
            }
        };
    
        const handleAction = (issueName: string) => {
            const handler = issueHandlers[issueName];
            if (handler) {
                handler();
            } else {
                console.error('No handler found for:', issueName);
            }
        };
    
        const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
            setSelectedTradeItem(event.target.value as string);
        };
    
        return (
        <div>
            <FormControl fullWidth size="small" sx={{ marginTop: 2 }} error={tradeError}>
                <InputLabel id="trade-name-select-label">Select a trade</InputLabel>
                <Select
                    labelId="trade-name-select-label"
                    onChange={(e: any) => handleSelectChange(e)}
                    value={selectedTradeItem}
                    label="Select a trade"
                >
                    {tradeNames.map((tradeName, index) => (
                        <MenuItem key={index} value={tradeName}>
                            {tradeName}
                        </MenuItem>
                    ))}
                </Select>
                {tradeError && (
                    <FormHelperText error>Selecting a trade is required</FormHelperText>
                )}
            </FormControl>

            <TextField
                fullWidth
                label="Enter your question"
                value={question}
                onChange={handleQuestionChange}
                margin="normal"
                size="small"
                error={questionError}
                helperText={questionError ? "Question is required" : ""}
            />
            <Box p={3}>
                <Typography variant="h6" gutterBottom>
                    Enter your expected answers
                </Typography>
                <Grid container spacing={2}>
                    {optionInputs.map((value, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <TextField
                                fullWidth
                                size='small'
                                label={`Option ${index + 1}`}
                                value={value}
                                onChange={handleInputChange(index)}
                                margin="none"
                                variant="outlined"
                            />
                        </Grid>
                    ))}
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddOption}
                    sx={{ mt: 2 }}
                >
                    <AddIcon /> Add Option
                </Button>
            </Box>
            <Box
                display="flex"
                justifyContent="right"
                alignItems="right"
                sx={{ paddingBottom: 2, py: 1 }}
            >
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={GoBack}
                    sx={{ marginTop: '16px', marginRight: '9px' }}
                >
                   Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleFormSubmit}
                    sx={{ marginTop: '16px' }}
                >
                    Submit
                </Button>
            </Box>
            <CustomDialog
                open={openConfirmDialog}
                onClose={() =>setOpenConfirmDialog(false)}
                success={createSuccess}
                error={error}
                Content={createSuccess ? "Send Successfully" : "Failed"}
            />
        </div>
    );
};

export default AddQuestion;
